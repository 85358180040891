import React, { memo, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { colors, spaces } from 'libs/theme'
import { getLocalItem, setLocalItem } from 'libs/local'

import { Grid, Text } from 'components/elements'
import { Dialog } from 'components/elements/Dialog'
import { Button } from 'components/elements/Buttons'
import Icons from 'components/elements/Icons'

const Popup = memo(({ isOpen = false, close, id, image, mImage }) => {
  const { breakpoints } = useSelector(state => state.default)
  const [isOpenPopup, setisOpenPopup] = useState(false)

  const setNotOpenToday = () => {
    let obj = {}
    const popup = getLocalItem('sirs-popup-close')
    if (popup) obj = popup
    obj[id] = new Date().getTime() + 1000 * 60 * 60 * 24
    setLocalItem('sirs-popup-close', obj)
    close()
  }

  useEffect(() => {
    const popup = getLocalItem('sirs-popup-close')
    if (popup && popup[id] && popup[id] > new Date().getTime()) setisOpenPopup(false)
    else setisOpenPopup(true)
  }, [])

  if (!isOpenPopup) return null

  return (
    <Dialog isOpen={isOpen} close={close} paperProps={{ style: { borderRadius: 0 } }}>
      <Grid padding={spaces.component.s3.px}>
        <Grid columns='fit-content(100%) fit-content(100%)' justify='space-between' align='center' marginTop={spaces.component.s3.px} padding={`0 ${spaces.component.s3.px}`}>
          <Button variant='text' onClick={setNotOpenToday}>
            <Grid columns='fit-content(100%) fit-content(100%)' gap='8px' align='center'>
              <Grid width='14px' height='14px' borderRadius='100%' border={`1px solid ${colors.black}`} />
              <Text>오늘 하루 보지 않기</Text>
            </Grid>
          </Button>
          <Button type='text' onClick={close}>
            <Icons icon='close' color={colors.grey.g600} width='24' height='24' />
          </Button>
        </Grid>
        <Grid marginTop={spaces.component.s3.px}>
          <img src={breakpoints.xs ? image : (mImage || image)} style={{ maxWidth: !breakpoints.md ? '100%' : '320px' }} />
        </Grid>
      </Grid>
    </Dialog>
  )
}, (p, n) => {
  if (p.isOpen !== n.isOpen) return false
  return true
})

export default Popup
