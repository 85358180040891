import React, { memo, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import ClayfulLayout from 'components/layouts/clayful'
import GNBLayout from 'components/layouts/GNB'
import UserLayout from 'components/layouts/user'

import { colors, spaces } from 'libs/theme'
import { storeItems, doctors, mainItems } from 'libs/items'
import { getUrlQuery, shuffle } from 'libs/string'
import { doWithTagManager } from 'libs/local'

import { Grid, Text } from 'components/elements'
import Slider from 'components/elements/Slider'
import { Button, Tag } from 'components/elements/Buttons'
import Icons from 'components/elements/Icons'

import Item from 'components/treatment/Item'
import Popup from 'components/commons/Popup'
import { setAlert } from 'store/default'

const Main = memo(() => {
  const router = useRouter()
  const dispatch = useDispatch()
  const q = getUrlQuery(router.asPath)
  const { clayful, breakpoints, env, banner, user } = useSelector(state => state.default)

  const [isInit, setisInit] = useState(false)
  const [width, setwidth] = useState()
  const [products, setproducts] = useState()
  const [collection, setcollection] = useState()
  const [subjectList, setsubjectList] = useState()
  const [subjectMain, setsubjectMain] = useState()
  const [section1Journals, setsection1Journals] = useState()
  const [banners, setbanners] = useState()
  const [popup, setpopup] = useState()
  const [isopenPopup, setisopenPopup] = useState(true)

  const getCollection = async () => {
    const { success, data } = await clayful.getCollections({ page: 1, limit: 10 })
    if (success) {
      data.sort((a, b) => a.meta.sort.raw < b.meta.sort.raw ? 1 : -1)
      const collections = data.filter(d => d.meta.available)
      const finded = collections.find(c => c.meta.is_main)
      if (finded) {
        getProducts(finded._id)
        setcollection(finded)
      }
    }
  }

  const getProducts = async (collection) => {
    const { success, data } = await clayful.getProducts({ collection })
    if (success) setproducts(data)
  }

  const getJournals = async (tag, callback, size = 3) => {
    const { success, data } = await window.api.request('get', 'journal', { tags: [window.encodeURIComponent(tag)], page: 1, page_size: size })
    if (success) callback(data.results)
  }

  const getBanners = async () => {
    const { success, data } = await window.api.request('get', 'banners')
    if (success) {
      setbanners(data.results.filter(r => r.subtitle === mainItems.banner.slide))
      setpopup(data.results.find(r => r.subtitle === mainItems.banner.popup))
    }
  }

  const checkAuth = () => {
    if (q.check_auth) {
      router.push('/')
      if (!user.is_identity_verified) {
        dispatch(setAlert({ body: '활동을 위해 본인인증이 필요합니다.<br />본인인증을 진행해주세요.' }))
        router.push('/sir')
      }
    }
  }

  useEffect(() => {
    if (user) checkAuth()
  }, [user])

  useEffect(() => {
    getJournals(storeItems.journalTags.subjectMain.tag, setsubjectMain, storeItems.journalTags.subjectMain.size)
    getJournals(storeItems.journalTags.subjectList.tag, setsubjectList, storeItems.journalTags.subjectList.size)
    getJournals(storeItems.journalTags.section1.tag, setsection1Journals, storeItems.journalTags.section1.size)
    getBanners()
  }, [])

  useEffect(() => {
    if (breakpoints) setwidth(breakpoints.width)
  }, [breakpoints])

  useEffect(() => {
    if (!isInit && clayful && env) {
      getCollection()
      setisInit(true)
    }
  }, [clayful, env])

  return (
    <GNBLayout isFooter isBanner>
      <UserLayout>
        {
          !!popup && <Popup id={popup.id} isOpen={isopenPopup} image={popup.image} mImage={popup.image_mobile} close={() => setisopenPopup(false)} />
        }
        <ClayfulLayout>
          <Grid gap={spaces.contents.s4.px} style={{ marginTop: banner ? 40 : 0 }}>
            <Grid gap={spaces.contents.s2.px}>
              <Grid padding={`${spaces.contents.s3.px} ${spaces.default.px}`} color={colors.grey.g100}>
                <Grid>
                  <Text variant='subtitle2' color={colors.grey.g600}>남들에겐 털어놓기 힘든 고민은 무엇인가요?</Text>
                  <Text variant='title3'>의사에게 비대면 진료받기</Text>
                  <Grid marginTop={spaces.contents.s1.px} columns='1fr 1fr 1fr' gap={spaces.component.s4.px}>
                    <Button variant='text' padding='0' radius={spaces.radius.card.px} style={{ boxShadow: '0 8px 10px 0 rgba(0, 0, 0, 0.05)' }}
                          onClick={() => doWithTagManager('gtm_click_home_prescribe_hairloss', () => router.push('/prescribe/hairloss'))}>
                      <Grid color={colors.white} width='100%' height='140px' justify='center' position='relative'>
                        <Grid padding={spaces.component.s4.px}><Text variant='body1'>탈모</Text></Grid>
                        <Grid position='absolute' width='100%' maxWidth='100px' height='100%' image='/images/category/hairloss.png' imagePosition='center bottom' imageSize='contain' />
                      </Grid>
                    </Button>
                    <Button variant='text' padding='0' radius={spaces.radius.card.px} style={{ boxShadow: '0 8px 10px 0 rgba(0, 0, 0, 0.05)' }}
                            onClick={() => doWithTagManager('gtm_click_home_prescribe_insomnia', () => router.push('/prescribe/insomnia'))}>
                      <Grid color={colors.white} width='100%' height='140px' justify='center' position='relative'>
                        <Grid padding={spaces.component.s4.px}><Text variant='body1'>불면증</Text></Grid>
                        <Grid position='absolute' width='100%' maxWidth='100px' height='100%' image='/images/category/insomnia.png' imagePosition='center bottom' imageSize='contain' />
                      </Grid>
                    </Button>
                    <Button variant='text' padding='0' radius={spaces.radius.card.px} style={{ boxShadow: '0 8px 10px 0 rgba(0, 0, 0, 0.05)' }}
                            onClick={() => doWithTagManager('gtm_click_home_prescribe_acne', () => router.push('/prescribe/acne'))}>
                      <Grid color={colors.white} width='100%' height='140px' justify='center' position='relative'>
                        <Grid padding={spaces.component.s4.px}><Text variant='body1'>여드름</Text></Grid>
                        <Grid position='absolute' width='100%' maxWidth='100px' height='100%' image='/images/category/acne.png' imagePosition='center bottom' imageSize='contain' />
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {
                !!banners && banners.length > 0 ? (
                  <Grid display='block' width='100%' height='140px' overflow='hidden'>
                    <Slider centerMode centerPadding={spaces.default.px} autoplay autoplaySpeed={3500}>
                      {
                        banners.map((b, index) => (
                          <Button key={b.id} variant='text' padding={`0 ${spaces.component.s3.px}`}
                                  onClick={() => doWithTagManager(`gtm_click_home_banner_${index + 1}`, () => router.push(b.link))}>
                            <img src={b.image} alt={b.title} width='100%' />
                          </Button>
                        ))
                      }
                    </Slider>
                  </Grid>
                ) : (
                  <Grid padding={`0 ${spaces.default.px}`} borderRadius={spaces.radius.medium.px}>
                    <Grid height='140px' color={colors.grey.g200} />
                  </Grid>
                )
              }
              <Grid>
                <Grid padding={`0 ${spaces.default.px}`}>
                  <Text variant='subtitle3' color={colors.grey.g600}>당장 물어볼 곳이 없다면</Text>
                  <Text variant='title3'>썰즈팀에게 물어보세요</Text>
                </Grid>
                <Grid marginTop={spaces.contents.s1.px}>
                  <Grid padding={`0 ${spaces.default.px} 8px`} overflow='auto' display='flex' gap={spaces.component.s4.px}>
                    {
                      shuffle(doctors).map((doctor, index) => (
                        <Grid key={index} justify='center' gap={spaces.component.s4.px}>
                          <Grid image={doctor.image} width='142px' height='142px' borderRadius={spaces.radius.image.px} style={{ boxShadow: '0 8px 10px 0 rgba(0, 0, 0, 0.05)' }} />
                          <Grid>
                            <Text variant='body1' color={colors.grey.g800} align='center'>{doctor.name}</Text>
                            <Text variant='caption2' color={colors.grey.g700} align='center'>{doctor.hospital}</Text>
                          </Grid>
                        </Grid>
                      ))
                    }
                  </Grid>
                  <Grid marginTop={spaces.contents.s1.px} padding={`0 ${spaces.default.px}`}>
                    <Button variant='outline' color={colors.primary.normal} padding='0'
                            onClick={() => doWithTagManager('gtm_click_home_counsel_go', () => router.push('/community/counsel'))}>
                      <Grid display='flex' gap={spaces.component.s3.px} align='center' height='52px'>
                        <Text variant='subtitle2' color={colors.primary.normal}>전문가 Q&A 더보기</Text>
                        <Icons icon='arrow-curved' width='18' height='18' rotate='90' />
                      </Grid>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {
              !!width && !!collection && (
                <Grid>
                  <Button variant='text' radius='0' disableRipple padding='0'
                          onClick={() => doWithTagManager('gtm_click_home_shop_banner', () => router.push('/store/project'))}>
                    <Grid align='end'>
                      <Grid style={{ top: 0, left: 0, right: 0, zIndex: 0 }}>
                        <Grid display='block' width='100%' overflow='hidden'>
                          <img src={collection.thumbnail.url} width='100%' />
                        </Grid>
                      </Grid>
                      <Grid padding={`0 ${spaces.default.px}`} style={{ zIndex: 2 }}>
                        {
                          collection.meta.c_label && (
                            <Grid marginTop={spaces.component.s4.px}>
                              <Tag padding={`${spaces.component.s1.px} ${spaces.component.s2.px}`}
                                       backgroundColor={colors.primary.light} color={colors.white}>
                                {collection.meta.c_label}
                              </Tag>
                            </Grid>
                          )
                        }
                        {
                          !!collection.name && (
                            <Grid marginTop={spaces.component.s2.px}>
                              <Text variant='title1' align='left'>{collection.name}</Text>
                            </Grid>
                          )
                        }
                        {
                          !!collection.description && (
                            <Grid marginTop={spaces.component.s3.px}>
                              <Text variant='body3' align='left' html={collection.description} />
                            </Grid>
                          )
                        }
                      </Grid>
                    </Grid>
                  </Button>
                  {
                    !!products && products.length > 2 && (
                      <Grid width='100%' padding='0 0 12px 0' marginTop={spaces.component.s4.px} style={{ overflowX: 'auto' }}>
                        <Grid display='flex' padding={`0 ${spaces.default.px}`} gap={spaces.component.s3.px}>
                          {
                            products.sort((a, b) => (b.meta.sort?.raw || 0) - (a.meta.sort?.raw || 0)).map((p, index) => (
                              <Button key={p._id} padding='0' radius={spaces.radius.image.px} onClick={() => doWithTagManager(`gtm_click_home_shop_item_${index + 1}`, () => router.push(`/store/products/${p._id}`))}>
                                <Grid image={p.thumbnail.url} width='90px' height='90px' borderRadius={spaces.radius.image.px} />
                              </Button>
                            ))
                          }
                        </Grid>
                      </Grid>
                    )
                  }
                </Grid>
              )
            }
            <Grid gap={spaces.contents.s1.px} padding={`0 ${spaces.default.px}`}>
              <Grid>
                <Text variant='subtitle3' color={colors.grey.g600}>의학 저널로 보는</Text>
                <Text variant='title3'>탈모에 대한 모든 것</Text>
              </Grid>
              <Grid overflow='hidden' borderRadius={spaces.radius.medium.px} style={{ boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.05)' }}>
                {
                  !!subjectMain && subjectMain.length > 0 && (
                    <Button variant='text' padding='0' radius='0'
                            onClick={() => doWithTagManager('gtm_click_home_journal_list_0', () => router.push(`/community/journal/${subjectMain[0].id}`))}>
                      <Grid width='100%' image={subjectMain[0].image} imageGradient={0.4} padding={spaces.contents.s1.px}>
                        <Grid gap={spaces.component.s3.px}>
                          <Text variant='caption2' color={colors.white} ellipsis='1' align='left'>의학 저널</Text>
                          <Text variant='title3' color={colors.white} ellipsis='2' align='left'>{subjectMain[0].title}</Text>
                        </Grid>
                        <Grid marginTop='86px'>
                          <Text variant='body2' color={colors.white} ellipsis='1' align='left'>
                            {
                              subjectMain[0].new_tags.filter(t => t.indexOf('#') === -1).map(t => `#${t}`).join(' ')
                            }
                          </Text>
                        </Grid>
                      </Grid>
                    </Button>
                  )
                }
                {
                  !!subjectList && subjectList.length > 0 && (
                    <Grid padding={`${spaces.component.s4.px} ${spaces.component.s3.px}`}>
                      {
                        subjectList.map((l, index) => (
                          <Item key={l.id} image={l.image} borderColor={colors.transparent}
                                onClick={() => doWithTagManager(`gtm_click_home_journal_list_${index + 1}`, () => router.push(`/community/journal/${l.id}`))}>
                            <Text align='left' variant='body3' ellipsis='2'>{l.title}</Text>
                          </Item>
                        ))
                      }
                    </Grid>
                  )
                }
              </Grid>
            </Grid>
            <Grid padding={`0 ${spaces.default.px}`}>
              <Grid columns='1fr 85px' align='center'>
                <Grid>
                  <Text variant='subtitle3' color={colors.grey.g600}>빠르게 달라지고 싶은 당신을 위해</Text>
                  <Text variant='title3'>병원 시술을 모아봤어요</Text>
                </Grid>
                <Button height='fit-content' onClick={() => doWithTagManager('gtm_click_home_treatment_all', () => router.push('/treatment/hair/implant'))}>
                  <Grid align='center' columns='1fr 20px' gap={spaces.component.s2.px}>
                    <Text>전체보기</Text>
                    <Icons icon='arrow-curved' width='18' height='18' rotate='90' />
                  </Grid>
                </Button>
              </Grid>
              <Grid marginTop={spaces.contents.s1.px} gap={spaces.component.s4.px}>
                <Item image='/images/treatment/eyebrow-tattoo.jpg'
                      onClick={() => doWithTagManager('gtm_click_home_treatment_list_1', () => router.push('/treatment/skin/microblading'))}>
                  <Text align='left' variant='subtitle3' ellipsis='1'>짙은 인상을 위한 <b>눈썹 문신</b></Text>
                  <Text align='left' variant='caption2' color={colors.grey.g600}>눈썹 문신 보러가기</Text>
                </Item>
                <Item image='/images/treatment/teeth-whitening.jpg'
                      onClick={() => doWithTagManager('gtm_click_home_treatment_list_2', () => router.push('/treatment/dental/whitening'))}>
                  <Text align='left' variant='subtitle3' ellipsis='1'>웃는 인상을 위한 <b>치아미백</b></Text>
                  <Text align='left' variant='caption2' color={colors.grey.g600}>치아미백 보러가기</Text>
                </Item>
                <Item image='/images/treatment/beard-hair-removal.jpg'
                      onClick={() => doWithTagManager('gtm_click_home_treatment_list_3', () => router.push('/treatment/skin/beard'))}>
                  <Text align='left' variant='subtitle3' ellipsis='1'>깔끔한 인상으로 <b>수염제모</b></Text>
                  <Text align='left' variant='caption2' color={colors.grey.g600}>수염제모 보러가기</Text>
                </Item>
              </Grid>
            </Grid>
            {
              !!section1Journals && section1Journals.length > 0 && (
                <Grid>
                  <Grid padding={`0 ${spaces.default.px}`}>
                    <Text variant='subtitle3' color={colors.grey.g600}>걱정만 하지 말고 제대로 알아보자</Text>
                    <Text variant='title3'>남자의 성 건강 팩트 체크</Text>
                  </Grid>
                  <Grid display='block' overflow='auto'>
                    <Grid height='236px' width='fit-content' display='flex' flexWrap='nowrap' padding={`0 ${spaces.default.px}`} gap={spaces.component.s4.px} marginTop={spaces.contents.s1.px}>
                      {
                        section1Journals.map((j, index) => (
                          <Button key={j.id} width='150px' align='left' variant='text' padding='0' overflow='visible' alignItems='start'
                                    onClick={() => doWithTagManager(`gtm_click_home_journal_card_${index + 1}`, () => router.push(`/community/journal/${j.id}`))}>
                            <Grid flexBasis='150px' height='220px' align='start' overflow='hidden' borderRadius={spaces.radius.medium.px} flexShrink='0' style={{ boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.05)' }}>
                              <Grid height='150px' image={j.image} />
                              <Grid padding={`${spaces.component.s4.px} ${spaces.component.s3.px}`}>
                                <Text variant='body2' ellipsis='2'>{j.title}</Text>
                              </Grid>
                            </Grid>
                          </Button>
                        ))
                      }
                    </Grid>
                  </Grid>
                </Grid>
              )
            }
          </Grid>
        </ClayfulLayout>
      </UserLayout>
    </GNBLayout>
  )
}, () => {
  return true
})

export default Main
