import React, { memo } from 'react'
import SliderM from 'react-slick'

/**
 *** slider component ***
 * react-slick 참고 - https://react-slick.neostack.com/
**/
const Slider = memo(({
  autoplay = false, autoplaySpeed = 2200,
  slidesToShow = 1, slidesToScroll = 1, speed = 800, initialSlide = 1, infinite = true,
  dots = true, arrows = false, nextArrow, prevArrow, centerMode = false, centerPadding,
  children
}) => {
  const settings = {
    autoplay,
    autoplaySpeed,
    dots,
    infinite,
    initialSlide,
    speed,
    slidesToShow,
    slidesToScroll,
    arrows,
    nextArrow,
    prevArrow,
    centerMode,
    centerPadding
  }

  return (
    <SliderM {...settings}>
      {children}
    </SliderM>
  )
}, (prev, next) => {
  if (prev.children !== next.children) return false
  return true
})

export default Slider
