import React, { memo } from 'react'

import { colors, spaces } from 'libs/theme'

import { Grid, Text } from 'components/elements'
import { Button } from 'components/elements/Buttons'
import Icons from 'components/elements/Icons'

const Item = memo(({ image, children, borderColor = colors.grey.g300, onClick }) => {
  return (
    <Button padding={spaces.component.s4.px} variant='outline' color={borderColor} onClick={onClick} disableRipple={!onClick}>
      <Grid display='flex' justify='space-between' width='100%' gap={spaces.component.s4.px} align='center'>
        {
          !!image && <Grid image={image} flexBasis='42px' flexShrink='0' height='42px' borderRadius={spaces.radius.small.px} />
        }
        <Grid gap={spaces.component.s1.px} flexGrow='1'>
          {children}
        </Grid>
        {
          !!onClick && (
            <Grid justify='center' flexBasis='20px' flexShrink='0'>
              <Icons icon='arrow-curved' width='18' height='18' rotate='90' />
            </Grid>
          )
        }
      </Grid>
    </Button>
  )
}, () => {
  return true
})

export default Item
